<template>
    <div>
        <template v-if="loaded"> 
            <h2>{{ $t("parrainage.solde") }}</h2>
            <div class="box">
                <b>{{ $t("parrainage.solde_point") }} :</b> {{ solde }}
            </div>
            <h2>{{ $t("global.historique_parrainage") }}</h2>
            <div class="box">

            <CustomTable
                id_table="parrainage_historique"
                :items="filtredHistorique"
                :busy.sync="table_busy"
                primaryKey="historiquefidelite_id"
                :hide_if_empty="true"
                :checkboxes="false"
                :filtre_general="false"
                :display_action_button="false"
            />
            </div>
        </template>
        
        <LoadingSpinner class="col-12" v-if="!loaded" />
       
    </div>
</template>

<script type="text/javascript">
    import Vue from 'vue'
    import TableAction from "@/mixins/TableAction.js"
    import Parrainage from "@/mixins/Parrainage.js";
    import CustomTable from "GroomyRoot/components/Table/CustomTable"
    import LoadingSpinner from 'GroomyRoot/components/Logos/LoadingSpinner_35'

    export default {
        name: "ParrainageHistorique",
        mixins: [TableAction, Parrainage],
        props: ['code_parrain'],
        data () {
            return {
                loaded: false,
                solde: null,
                table_busy: true,
                historique: null
            }
        },
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.loaded = false
                await this.loadComponentCodeParrain()
                await this.loadComponentHistorique()
                this.loaded = true
            },
            async loadComponentHistorique() {
                this.table_busy = true
                this.historique = await this.loadHistorique()
                this.table_busy = false
            },
            async loadComponentCodeParrain() {
                const solde = await this.loadSolde()
                this.solde = solde.solde;
            }
        },
        computed: {
            filtredHistorique: function() {
                return this.historique
            }
        },
        components: {
            CustomTable,
            LoadingSpinner
        }
    }
</script>
